<template>
  <div>
    <!-- 左侧导航栏 -->
    <menu-wrapper active-index="">
      <div class="secondTitle">交易大厅</div>
      <el-form
        ref="form"
        :model="form"
        v-loading="loading"
        class="exchangeCards"
      >
        <el-form-item label="类型:">
          <el-radio-group
            v-model="form.cardtype"
            v-for="(item, index) in cardtypes"
            :key="index"
            @change="onCardTypeChange"
          >
            <el-radio
              :v-model="item.Id"
              :label="item.Id"
              style="margin-right: 10px"
              >{{ item.Name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="今日每张优惠券可兑换:">
          <el-radio-group
            v-model="form.cardpricetype"
            v-for="(item, index) in pricetypes"
            :key="index"
          >
            <el-radio
              :v-model="item.Id"
              :label="item.Id"
              style="margin-right: 10px"
              >{{ Number(item.Name) * cardRate + "颗云淘豆" + $currency }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        
        <el-form-item label="卡号/卡密">
          <div style="max-height: 500px; overflow: hidden; overflow-y: scroll">
            <div
              v-for="(item, index) in form.exchangeCards"
              :key="index"
              class="cardline"
              style="padding-left: 0px !important"
            >
              <el-input
                v-model="item.CardNumber"
                placeholder="请输入卡号"
                style="width: 39%"
              ></el-input>
              <el-input
                v-model="item.CardSecret"
                placeholder="请输入卡密"
                style="width: 39%; margin-left: 1%"
              ></el-input>
              <el-button
                type="button"
                @click="onDelete(index)"
                v-if="index > 0"
                style="margin-left: 5px"
                >- 删除</el-button
              >
            </div>
          </div>
          <div class="cardline">
            <el-button type="button" @click="onAdd">+ 添加</el-button>
            <el-button type="button" @click="dialogVisible = true"
              >批量导入</el-button
            >
            <p style="color: red">
              注意：每次只能兑换同一种优惠券类型和同一种面值。单次兑换最大数
              {{ maxExchangeNum }} 张。
            </p>
          </div>
        </el-form-item>
        <el-form-item style="padding-left: 80px">
          <el-radio v-model="radioCheck" label="1"
            >我已知悉权益与责任，确认卡密准确无误；同意本项下优惠券的权益和义务，自出售提交之日起转化为余额。</el-radio
          >
          <br />
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>

      <el-dialog
        title="批量导入"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
        :modal-append-to-body="false"
      >
        <el-input
          type="textarea"
          :rows="10"
          placeholder="卡号卡密用空格隔开,多个卡号用换行隔开,例如：A123456 P123456"
          v-model="textarea"
        >
        </el-input>

        <span style="font-size: 12px; color: red; line-height: 40px"
          >注意：每次只能兑换同一种优惠券类型和同一种面值。 单次兑换最大数{{maxExchangeNum}}张。
        </span>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="onImport">导 入</el-button>
        </span>
      </el-dialog>
    </menu-wrapper>
  </div>
</template>

<script>
import menuWrapper from "@/components/user/common/menu-wrapper.vue";
export default {
  components: {
    menuWrapper,
  },
  data() {
    return {
      form: {
        exchangeCards: [{ CardNumber: "", CardSecret: "" }],
        cardtype: 0,
        cardpricetype: 0,
      },
      radioCheck: 0,
      cardtypeAll: [],
      cardtypes: [],
      pricetypes: [],
      cardRate: 0.0,
      maxExchangeNum: 0,
      loading: true,
      dialogVisible: false,
      textarea: "",
    };
  },
  created() {
    this.pageLoad();
  },
  methods: {
    pageLoad() {
      this.loading = true;
      this.$axios
        .get("/api/CardsOrder/GetExchangeCardInfo")
        .then((res) => {
          this.cardtypeAll = res.data.CardTypeInfo;
          this.cardtypes = this.cardtypeAll.filter((t) => t.ParentId == 0);
          if (this.cardtypes.length > 0) {
            this.form.cardtype = this.cardtypes[0].Id;
            this.pricetypes = this.cardtypeAll.filter(
              (t) => t.ParentId == this.form.cardtype
            );
            if (this.pricetypes.length > 0) {
              this.form.cardpricetype = this.pricetypes[0].Id;
            }
          }
          this.cardRate = res.data.CardRate;
          this.maxExchangeNum = res.data.MaxExchangeNum;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onCardTypeChange(e) {
      this.pricetypes = this.cardtypeAll.filter((t) => t.ParentId == e);
    },
    onAdd() {
      this.loading = true;
      var last = this.form.exchangeCards[this.form.exchangeCards.length - 1];
      if (last.CardNumber.length == 0 || last.CardSecret.length == 0) {
        this.$alert("请先把卡号、卡密填写完整", "系统提示", {
          confirmButtonText: "确定",
          closeOnClickModal: true,
        });
        this.loading = false;
        return;
      }
      
      if (this.form.exchangeCards.length >= this.maxExchangeNum) {
        this.$alert(
          "已到达单次兑换最大数 " + this.maxExchangeNum + " 张,不能继续添加。",
          "系统提示",
          {
            confirmButtonText: "确定",
            closeOnClickModal: true,
          }
        );
        this.loading = false;
        return;
      }
      this.form.exchangeCards.push({ CardNumber: "", CardSecret: "" });
      this.loading = false;
    },
    onDelete(index) {
      if (this.form.exchangeCards.length > 1) {
        this.form.exchangeCards.splice(index, 1);
      }      
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    logExchangeCards(d){
      this.$axios
        .post("/api/CardsOrder/LogExchangeCards", {data:d},{
            headers: {
            'Content-Type': 'application/json'
          }
          
        })
        .then((res) => {
		
		    });
    },
    onImport() {
      //debugger;
      if (!this.textarea) {
        this.$message({
          message: "请输入至少一个卡号和卡密",
          type: "warning",
        });
        return;
      }
      var importList = [];
      var textare = this.textarea;
      var cards = textare.trim().split("\n");
      for (var i = 0; i < cards.length; i++) {
        var element = cards[i];
        var items = element.trim().split(" ");
        if (items.length != 2) continue;
        importList.push({ CardNumber: items[0], CardSecret: items[1] });
      }

      if (importList.length > this.maxExchangeNum) {
        this.$alert(
          "单次兑换最多不能超过 " + this.maxExchangeNum + " 张。",
          "系统提示",
          {
            confirmButtonText: "确定",
            closeOnClickModal: true,
          }
        );
        return;
      }
      
      this.$confirm(
        "当前导入共：" + importList.length + " 条。是否开始导入。",
        "系统提示",
        {
          confirmButtonText: "导 入",
          cancelButtonText: "取 消",
          type: "warning",
        }
      )
        .then(() => {
          this.form.exchangeCards = importList; //this.form.exchangeCards.concat(importList);
          this.dialogVisible = false;
        })
        .catch(() => {
          this.dialogVisible = false;
        });
    },
    onSubmit(e) {
      if (this.radioCheck == 0) {
        this.$alert("请确认优惠券交易协议", "系统提示", {
          confirmButtonText: "确定",
          closeOnClickModal: true,
        });
        return;
      }
      if (this.form.exchangeCards.length == 0) {
        this.$alert("请输入至少一个卡号和卡密", "系统提示", {
          confirmButtonText: "确定",
          closeOnClickModal: true,
        });
        return;
      }
      var elist = this.form.exchangeCards.filter(
        (t) => t.CardNumber.length == 0 || t.CardSecret.length == 0
      );
      if (elist.length > 0) {
        this.$alert("卡号、卡密不能为空，请认真填写。", "系统提示", {
          confirmButtonText: "确定",
        });
        return;
      }
     
      if (this.form.exchangeCards.length > this.maxExchangeNum) {
        this.$alert(
          "单次兑换最多不能超过 " + this.maxExchangeNum + " 张。",
          "系统提示",
          {
            confirmButtonText: "确定",
            closeOnClickModal: true,
          }
        );      
        return;
      }
      this.loading = true;
      this.$axios
        .post("/api/CardsOrder/ExchangeCards", this.form,{
          timeout:1800000 //30分钟
        })
        .then((res) => {
          if (res.data) {
            this.$alert(
              "订单提交成功，系统正在处理中，请稍后到 [个人中心] 查看到账详情。",
              {
                confirmButtonText: "确定",
                callback: (action) => {
                  this.form.exchangeCards = [
                    { CardNumber: "", CardSecret: "" },
                  ];
                  this.textarea = "";
                  this.$router.replace({
                    path: "/user/exchangeinfo?orderId=" + res.data,
                  });
                },
              }
            );
          }
          this.loading = false;
        })
        .catch((error) => {

          this.loading = false;
          console.log("兑换失败: "+JSON.stringify(error));          
          this.logExchangeCards(JSON.stringify(error));
          if (error.code === 'ECONNABORTED') {
            // 超时错误
            this.$alert("请求超时，请稍后重试。", {
              confirmButtonText: "确定",
              closeOnClickModal: true,
            });
          } else{           
            this.$alert("系统繁忙，请稍后重试", {
                  confirmButtonText: "确定",
                  closeOnClickModal: true,
                });
          }
         
        });
    },
  },
};
</script>
<style scoped>
.cardRate {
  color:  #ff6701;
  font-size: 16px;
  font-weight: 600;
}
.cardline {
  margin-top: 5px;
  padding-left: 80px;
}
.title {
  font-size: 30px;
  color: #757575;
  line-height: 68px;
}
/* .exchangeCards .el-radio__inner {
  top: auto !important;
  left: auto !important;
} */

.el-button--primary{
  background-color:   var(--ThemeColor,#ff6701);
    border-color:   var(--ThemeColor,#ff6701);
}
.el-button--primary:hover{
  opacity: 0.8;
}

.el-button--default:hover{
  border-color:   var(--ThemeColor,#ff6701);
  background-color:   var(--ThemeColor,#ff6701);
  color:white;
}

.el-button--button:hover{
  border-color:   var(--ThemeColor,#ff6701);
  background-color:   var(--ThemeColor,#ff6701);
  color:white;
}

</style>